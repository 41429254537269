<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <PageTitle :title="title" :subtitle="subtitle" />

    <section class="section">
      <div class="container">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Form
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <form
                @submit.prevent="
                  isUpdate ? updateCarExpense() : addCarExpense()
                "
              >
                <b-field label="Car" horizontal>
                  <b-select
                    placeholder="Select car"
                    required
                    v-model="form.car"
                  >
                    <option
                      v-for="(car, index) in cars"
                      :key="index"
                      :value="car['@id']"
                    >
                      {{ car.licensePlate }}, {{ car.model }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Date" horizontal>
                  <b-datepicker
                    v-model="form.createdAt"
                    :max-date="new Date()"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
                <b-field
                  label="Mileage"
                  :type="{
                    'is-danger': !isNewCarMileageValid
                  }"
                  :message="[
                    {
                      'New mileage can not be lower': !isNewCarMileageValid
                    },
                    { 'Car mileage at the time of expense': true }
                  ]"
                  horizontal
                  v-if="selectedCar"
                >
                  <b-input
                    v-model.number="form.carMileage"
                    placeholder="Car's mileage (optional)"
                  />
                </b-field>
                <b-field label="Expense type" horizontal>
                  <b-select
                    placeholder="Select type of car expense"
                    required
                    v-model="form.type"
                  >
                    <option
                      v-for="(expenseType, index) in expenseTypes"
                      :key="index"
                      :value="expenseType['@id']"
                    >
                      {{ expenseType.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Description" message="Summary" horizontal>
                  <b-input
                    v-model="form.description"
                    type="textarea"
                    maxlength="255"
                    placeholder="Additional information about the expense (optional)"
                  />
                </b-field>
                <b-field
                  label="Cost"
                  message="Car expense cost in €"
                  horizontal
                  required
                >
                  <b-input
                    v-model.number="form.cost"
                    placeholder="Total cost (€)"
                    required
                  />
                </b-field>
                <hr />
                <b-field label="Receipts" horizontal>
                  <b-upload v-model="form.imageFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <b-field horizontal>
                  <b-field grouped>
                    <div class="tags">
                      <span v-if="form.imageFile" class="tag is-primary">
                        {{ form.imageFile.name }}
                        <button
                          class="delete is-small"
                          type="button"
                          @click="deleteDropFile"
                        ></button>
                      </span>
                    </div>
                    <div class="control">
                      <b-button
                        @click="uploadImages"
                        type="is-primary"
                        :disabled="!!!this.form.imageFile"
                        >Upload</b-button
                      >
                    </div></b-field
                  ></b-field
                >

                <hr />

                <b-field horizontal>
                  <b-field grouped>
                    <div class="control">
                      <b-button
                        native-type="submit"
                        type="is-primary"
                        :disabled="!isFormValid"
                        >Submit</b-button
                      >
                    </div>
                    <div class="control">
                      <b-button type="is-primary is-outlined" @click="resetForm"
                        >Reset</b-button
                      >
                    </div>
                  </b-field>
                </b-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { httpClient } from '@/api/httpClient.js'
import PageTitle from '@/components/PageTitle.vue'

export default {
  components: {
    Navbar,
    Footer,
    PageTitle
  },
  props: {
    carId: {
      type: String
    },
    carExpenseId: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      expenseTypes: [],
      cars: [],
      form: {
        car: this.carId,
        createdAt: new Date(),
        type: null,
        cost: null,
        description: null,
        carMileage: null,
        imageFile: null,
        image: null
      }
    }
  },
  created() {
    this.getExpenseTypes()
    this.getCars()
    if (this.isUpdate) this.getCarExpense()
  },
  computed: {
    isUpdate() {
      return !!this.carExpenseId && this.carExpenseId !== '0'
    },
    selectedCar() {
      return this.cars.find(car => car['@id'] === this.form.car)
    },
    isFormValid() {
      return (
        this.form.car &&
        this.form.type !== '' &&
        this.form.cost &&
        this.isNewCarMileageValid
      )
    },
    isCarMileageChanged() {
      return this.carInitialMilage !== this.selectedCar.mileage
    },
    isNewCarMileageValid() {
      return this.selectedCar?.mileage <= this.form.carMileage
    },
    title() {
      return this.isUpdate ? 'Update car expense' : 'Add new car expense'
    },
    subtitle() {
      return this.isUpdate ? 'Update expense' : 'New car expense'
    }
  },
  watch: {
    selectedCar() {
      this.form.carMileage = this.selectedCar?.mileage
    }
  },
  methods: {
    getExpenseTypes() {
      this.isLoading = true

      httpClient
        .get('expense-types')
        .then(res => {
          this.isLoading = false
          this.expenseTypes = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getCars() {
      this.isLoading = true

      httpClient
        .get('/cars')
        .then(res => {
          this.isLoading = false

          this.cars = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getCarExpense() {
      this.isLoading = true

      httpClient
        .get(`car-expenses/${this.carExpenseId}`)
        .then(res => {
          this.isLoading = false
          this.form = res.data
          this.form.type = res.data.type['@id']
          this.form.createdAt = new Date(res.data.createdAt)
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    addCarExpense() {
      httpClient
        .post('/car-expenses', this.form)
        .then(() => {
          this.isLoading = false

          this.updateCarMileage()

          this.$buefy.snackbar.open({
            message: `Car expense has been added successfuly.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
          this.$router.replace({
            name: 'car',
            params: { carId: this.selectedCar.id.toString() }
          })
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `${err.message}`,
            type: 'is-danger',
            position: 'is-top',
            actionText: 'Retry'
          })
        })
    },
    updateCarExpense() {
      httpClient
        .put(`car-expenses/${this.carExpenseId}`, this.form)
        .then(() => {
          this.$buefy.snackbar.open({
            message: `Car expense has been updated.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
          this.$router.replace({
            name: 'car',
            params: { carId: this.selectedCar.id.toString() }
          })
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    updateCarMileage() {
      if (!this.isCarMileageChanged) return

      httpClient
        .put(`/cars/${this.selectedCar.id}`, {
          mileage: this.form.carMileage
        })
        .then(() => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Car mileage has been updated successfuly.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
          this.resetForm()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `${err.message}`,
            type: 'is-danger',
            position: 'is-top',
            actionText: 'Retry'
          })
        })
    },
    uploadImages() {
      const bodyFormData = new FormData()
      bodyFormData.append('file', this.form.imageFile)
      bodyFormData.append('type', 'image/jpeg')
      this.isLoading = true

      httpClient
        .post(`media-objects`, bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.isLoading = false
          this.form.image = res.data['@id']

          this.$buefy.snackbar.open({
            message: `Receipt uploaded successfully`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `${err.message}`,
            type: 'is-danger',
            position: 'is-top',
            actionText: 'Retry'
          })
        })
    },
    resetForm() {
      this.form = {
        car: null,
        type: null,
        cost: null,
        description: null,
        imageFile: null,
        image: null
      }
    },
    deleteDropFile() {
      this.form.imageFile = null
    }
  }
}
</script>

<style lang="scss"></style>
